import './App.scss';
import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import ReactGA from 'react-ga4';
ReactGA.initialize('G-EQ7WHEBP5C');

function getHeaderColor() {
  const header = document.getElementById('header');
  const headerBottom = window.scrollY + header.offsetHeight;
  const quotes = Array.from(document.getElementsByClassName('quotes'));
  const slides = Array.from(document.getElementsByClassName('lln-bg'));
  const testH = Array.from(document.getElementsByClassName('testimonial-header'));
  const tests = Array.from(document.getElementsByClassName('testimonial'));
  const contact = Array.from(document.getElementsByClassName('contact'));

  const elems = quotes.concat(slides).concat(testH).concat(tests).concat(contact);
  // 
  // top < headerBottom > top - bottom
  for (let i = elems.length - 1; i >= 0; i--) {
    // console.log(elems[i].scrollHeight)
    let elem = elems[i];
    if (elem.offsetTop <= headerBottom && headerBottom < elem.offsetTop + elem.offsetHeight) {
      const bgColor = window.getComputedStyle( elem ,null).getPropertyValue('background-color');
      let classColor = 'white';

      switch (bgColor) {
        case 'rgb(255, 0, 0)': // red
          classColor = 'red';
          break;
        case 'rgb(255, 255, 255)': // black
          classColor = 'black';
          break;
        default: // white
          classColor = 'white';
          break;
      }
      
      if (!Array.from(header.classList).includes(classColor)) {
        header.removeAttribute('class');
        header.classList.add(classColor);
      }
      // console.log(elem.offsetTop, window.scrollY + headerBottom)
      // console.log(elem.getElementsByTagName('footer')[0].innerHTML)
      // console.log(bgColor)
      break;
    }
  }
}

window.addEventListener('scroll', getHeaderColor);

// pulse animation delay
function setAnimationDelay() {
  const keywords = Array.from(document.getElementsByTagName('strong'));
  for (let i = keywords.length - 1; i >= 0; i--) {
    let elem = keywords[i];
    let delay = Math.floor(Math.random()*10);
    elem.style.animationDelay = `${delay}s`;
  }
}

window.onload = setAnimationDelay;

function App() {
  return (
    <article className="lln">
      <header id="header">
        <div className="lln-name"><h1>Llorraine Neithardt</h1></div>
        <nav className="menu">
          <a href="#about">About Llorraine</a> <a href="#contact">Book an Appointment</a> <a href="#contact">Contact Llorraine</a>
        </nav>
      </header>
      <section className="lln-bg">
        <img src="../lln_9410.jpg" alt="Llorraine at desk gesturing with her hand" className="lln-bg-image" style={{objectPosition: 'center 30%'}} />
        <img src="../lln_8807.jpg" alt="Llorraine at desk with pen in hand" className="lln-bg-image" style={{objectPosition: '45% 35%'}} />
        <img src="../lln_9230.jpg" alt="Llorraine peering" className="lln-bg-image" style={{objectPosition: 'center 15%'}} />
        <img src="../lln_9307.jpg" alt="Llorraine peering" className="lln-bg-image" style={{objectPosition: '60% center'}} />
        <img src="../lln_9370.jpg" alt="Llorraine's hands" className="lln-bg-image" style={{objectPosition: '55% center'}} />
      </section>
      {/*<section id="quotes" className="quotes">
        <div className="content">
          <blockquote>
            <p className="script2">Wha<span className="ss07">t</span>ever <span className="ss09">y</span>ou <span className="ss11">d</span>on<span className="sr-only">'</span>t know a<span className="ss11">b</span>ou<span className="ss08">t</span> <span className="ss09">y</span>oursel<span className="ss09">f</span> <br /><span className="ss06">r</span>eturns <span className="ss09">t</span>o <span className="ss08">y</span>ou as <strong><span className="ss09">f</span>at<span className="ss04">e</span></strong><span className="full-stop">.</span></p>
            <footer>Carl Jung</footer>
          </blockquote>
        </div>
      </section>*/}
      <section id="about" className="testimonials">
        <div className="content">
          <h2 className="testimonial-header">What People Have Said…</h2>
          <blockquote className="testimonial">
            <p style={{textAlign: 'center'}}>That bitch <strong>changed my life</strong>.</p>
            <footer>Sarah Johnson - Designer/Stylist</footer>
          </blockquote>
          <blockquote className="testimonial">
            <p>I have had the&nbsp;<strong>good fortune</strong>&nbsp;to know Llorraine Neithardt since the rollicking &rsquo;80s.&nbsp;Let it&nbsp;be known&nbsp;she&nbsp;is one of the&nbsp;<strong>wittiest and perceptive humans</strong>&nbsp;on the planet, pulling no punches and showing no mercy when it comes to saying her mind.&nbsp;Add to that a&nbsp;<strong>heart as big as the world</strong>, and you begin to get a bead on a&nbsp;<strong>truly original and remarkable soul</strong>.</p>
            <p>Earthlings, we have&nbsp;among us&nbsp;a&nbsp;<strong>rare and evolved human</strong>, a&nbsp;<strong>treasure</strong>.&nbsp;Llorraine&rsquo;s&nbsp;<strong>psychic readings</strong>&nbsp;(save your pennies and give this to yourself for a&nbsp;<strong>life-changing birthday present</strong>!) are nothing less than&nbsp;<strong>thrilling</strong>.&nbsp;She draws upon her&nbsp;<strong>prodigious scholarship</strong>,&nbsp;her&nbsp;<strong>high intelligence</strong>, and&nbsp;her&nbsp;<strong>true&nbsp;and astonishing psychic gift</strong>&nbsp;to give you a session that&nbsp;<strong>knocks your socks off</strong>&nbsp;and&nbsp;<strong>turns your head around more than once</strong>.&nbsp;Prepare for a&nbsp;<strong>gentle journey</strong>&nbsp;into your&nbsp;own&nbsp;<strong>deepest places</strong>, past, present, and future.&nbsp;Llorraine&nbsp;<strong>weaves and connects your own story</strong>, your&nbsp;<strong>human destiny</strong>, to&nbsp;the&nbsp;<strong>greatest&nbsp;and most profound stories&nbsp;of humankind</strong>.&nbsp;You get to see yourself in the&nbsp;<strong>widest&nbsp;context</strong>, an orientation to &ldquo;being&rdquo; that has&nbsp;a power&nbsp;to&nbsp;<strong>transform</strong>. Really!</p>
            <p>Llorraine has been a&nbsp;<strong>mentor</strong>&nbsp;to me over the years. I check in with her several times annually, sit in her&nbsp;<strong>fascinating digs</strong>,&nbsp;<strong>laugh my head off</strong>, smile at her&nbsp;<strong>blade-sharp sense of the ironic</strong>, and settle in for a&nbsp;<strong>serious investigation</strong>.</p>
            <p>Searching for a&nbsp;<strong>metaphor</strong>&nbsp;to encapsulate what a psychic reading with Llorraine is like, I came up with: it&rsquo;s like eating a&nbsp;<strong>large slice of the most delicious aged fruitcake</strong>. With every bite, there are&nbsp;<strong>different flavors and textures</strong>. Every ingredient is&nbsp;in itself&nbsp;a&nbsp;<strong>marvel</strong>, yet each belongs to the whole. And where that spectacular fruitcake&nbsp;is laced&nbsp;with&nbsp;<strong>aromatic rum</strong>, a session with Llorraine is&nbsp;suffused&nbsp;with the&nbsp;<strong>most spirit-enhancing, life-affirming, and earth-grounded guidance</strong>&nbsp;you&rsquo;ll ever encounter.</p>
            <footer>Fran Bull - Artist</footer>
          </blockquote>
          <blockquote className="testimonial">
            <p>I have had the&nbsp;<strong>honor and pleasure</strong>&nbsp;of knowing Llorraine Neithardt for&nbsp;the past&nbsp;18 years. Llorraine&rsquo;s&nbsp;<strong>deep understanding</strong>&nbsp;of the&nbsp;<strong>human psyche</strong>,&nbsp;<strong>powerful intuition</strong>, and&nbsp;<strong>profound sense of humor</strong>&nbsp;make her one of the&nbsp;<strong>most dynamic individuals</strong>&nbsp;I have come to know.&nbsp;Her&nbsp;<strong>knowledge of the esoteric</strong>&nbsp;and&nbsp;<strong>sensitivity to the human soul</strong>&nbsp;have&nbsp;not only&nbsp;been a&nbsp;<strong>guiding force</strong>&nbsp;but also&nbsp;a&nbsp;<strong>source of inspiration</strong>&nbsp;throughout my life.&nbsp;If you are&nbsp;truly&nbsp;interested in reaching your dreams, establishing a&nbsp;<strong>genuine relationship</strong>&nbsp;with the more&nbsp;<strong>mysterious aspects within</strong>, and walking the pathway toward a&nbsp;<strong>more conscious state of living</strong>, I&nbsp;<strong>highly recommend</strong>&nbsp;Llorraine.</p>
            <p><strong>Llorraine Neithardt rocks my world!</strong>&nbsp;The question is,&nbsp;<strong>are you ready</strong> for her to rock yours?</p>
            <footer>Constantine Ladikos - Educator/Researcher/Artist</footer>
          </blockquote>
          <blockquote className="testimonial">
            <p>Llorraine has been my&nbsp;<strong>life coach</strong>&nbsp;and&nbsp;<strong>mentor</strong>&nbsp;for the past 23 years, long before the phrase &ldquo;life coach&rdquo; was&nbsp;even&nbsp;invented. A session with her is always a&nbsp;<strong>deep and powerful experience</strong>&nbsp;for me.&nbsp;During a session she will combine her&nbsp;<strong>exceptional clairvoyant skills</strong>&nbsp;with a&nbsp;<strong>vast knowledge of psychology, metaphysics, mythology, and the sacred arts</strong>&nbsp;to guide me&nbsp;to&nbsp;find&nbsp;meaning from events in my life&nbsp;from&nbsp;the&nbsp;<strong>bigger picture</strong>&nbsp;of my&nbsp;<strong>soul&rsquo;s perspective and purpose</strong>.&nbsp;Llorraine has helped me&nbsp;to&nbsp;<strong>grow and evolve</strong>&nbsp;into the&nbsp;<strong>mystery and adventure</strong>&nbsp;that is my life in a way&nbsp;that&nbsp;no one else can.&nbsp;And she has a&nbsp;<strong>wicked sense of humor</strong>, to boot! I&nbsp;<strong>highly recommend</strong>&nbsp;her for an hour or a lifetime.</p>
            <footer>Amanda Register - Attorney/Artist</footer>
          </blockquote>
          <blockquote className="testimonial">
            <p>Llorraine Neithardt is a&nbsp;<strong>gifted visionary</strong>&nbsp;who is singular in&nbsp;<strong>esoteric knowledge</strong>&nbsp;and&nbsp;<strong>insight</strong>.&nbsp;We are&nbsp;<strong>fortunate</strong>&nbsp;enough to receive the benefit of her&nbsp;<strong>unique psychic gift</strong>&nbsp;which she offers with&nbsp;<strong>compassion&nbsp;to&nbsp;soul</strong>,&nbsp;<strong>keen intellect of mind</strong>, and&nbsp;<strong>utmost respect&nbsp;to&nbsp;individuation</strong>. Time with Neithardt is&nbsp;<strong>life-changing</strong>. As&nbsp;<strong>teacher, mentor, and guide</strong>&nbsp;she is&nbsp;<strong>unchallenged</strong>.</p>
            <footer>Michael Lisbona - Entrepreneur</footer>
          </blockquote>
          <blockquote className="testimonial">
            <p>Llorraine Neithardt is the&nbsp;<strong>most startling of all seers</strong>: Where others encourage fear; she sows&nbsp;<strong>common sense</strong>. She gives you the arms to&nbsp;<strong>fight your&nbsp;own&nbsp;demons</strong>&nbsp;and&nbsp;<strong>illuminates your understanding</strong>&nbsp;with&nbsp;<strong>solid references to myth and literature</strong>---she places you&nbsp;<strong>inside your own story</strong>&nbsp;and shows you how to&nbsp;<strong>make it come out right</strong>.</p>
            <p>I met her through a&nbsp;crazy fluke, and she&nbsp;has become a&nbsp;<strong>valued,&nbsp;trusted&nbsp;friend</strong>.</p>
            <footer>Joan Juliet Buck - Writer/Actor</footer>
          </blockquote>
        </div>
      </section>
      <section id="contact" className="contact">
        <div className="content">
          <h2 className="contact-header">Contact</h2>
          <blockquote>
            <p>To book an appointment with Llorraine call <a href="tel:2127578914" className="phone">(212) 757-8914.</a></p>
            <footer>The Last of the Landlines <small>(don't try to text ;-))</small></footer><br />
            <p className="magnolia-bold">Whatever you don't know
<br /> about yourself
<br /> returns to you as fate.</p>
            <footer>Carl Jung <small>(paraphrased)</small></footer>
          </blockquote>
        </div>
      </section>
      <footer className="footer">
        <a href="tel:2127578914" className="phone">(212) 757-8914</a> <span className="note">*Landline (don't try to text *;-))</span> <span className="divider">|</span> <a href="https://www.youtube.com/user/venusunplugged" target="_blank">
          <svg xmlns="http://www.w3.org/2000/svg" style={{verticalAlign: 'bottom'}} viewBox="0 0 64 64" width="2ch" height="2ch"><path d="M62.603 16.596a8.06 8.06 0 0 0-5.669-5.669C51.964 9.57 31.96 9.57 31.96 9.57s-20.005.04-24.976 1.397a8.06 8.06 0 0 0-5.669 5.669C0 21.607 0 32 0 32s0 10.393 1.356 15.404a8.06 8.06 0 0 0 5.669 5.669C11.995 54.43 32 54.43 32 54.43s20.005 0 24.976-1.356a8.06 8.06 0 0 0 5.669-5.669C64 42.434 64 32 64 32s-.04-10.393-1.397-15.404z" fill="currentColor"/><path d="M25.592 41.612L42.187 32l-16.596-9.612z" fill="#fff"/></svg> Venus Unplugged</a>
      </footer>
    </article>
  );
}

export default App;
